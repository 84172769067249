<template>
  <div id="contact">
    <div class="contact_warp">
      <div class="multiple_banner">
        <el-image style="width: 100%;border:0;" :src="url" fit="fill">
          <div slot="error" class="image-slot">
            <el-image style="width: 100%; height: 100%; border: 0" :src="defalutUrl" fit="fill" />
          </div>
        </el-image>
      </div>
      <!-- 横幅图  -->
      <div class="contact_">
        <div class="contact_title_warp">
          <div class="contact_title_main">联系我们</div>
          <div class="contact_title_path">网站首页/联系我们</div>
        </div>
        <!--   基础信息     -->
        <div class="contact_info_warp">
          <div class="contact_map_item">
            <!--            <h1>该功能正在开发中，敬请期待。</h1>-->
            <div class="col col23 no_margin_right">
              <div class="map_border">
                <OweMapBorderCompo
                    :address="baseInfo.coAddress"
                />
              </div>
            </div>
          </div>
          <div class="base_info_warp">
            <div class="corporate_name">{{ baseInfo.coName }}</div>
            <div class="other_info_warp">
              <div :title="baseInfo.coAddress ">地址：{{ baseInfo.coAddress }}</div>
              <div>电话：{{ baseInfo.coLandline }}</div>
              <div>邮编：{{ baseInfo.coZipCode }}</div>
              <div>邮箱：{{ baseInfo.coMailbox }}</div>
            </div>
          </div>
        </div>
        <div class="contact_link_warp">
          <!--    战略合作伙伴      -->
          <div class="link_item_warp">
            <div class="link_item_title">战略合作伙伴</div>
            <div class="link_item_list">
              <div class="list_item_branch" v-for="item in brg.data" :key="item.id">
                <el-link style="width: 100%" :href="item.linkUrl" :underline="false">
                  <el-card class="box-card" shadow="hover" style="height: 98px;">
                    <el-image style="height: 98px; border:0;" :src="preview+item.fileId"
                              fit="fill">
                      <div slot="error" class="image-slot">
                        <el-image style="width: 100%; height: 100%; border: 0" :src="defalutUrl" fit="fill" />
                      </div>
                    </el-image>
                  </el-card>
                </el-link>
              </div>
            </div>
            <div class="link_item_pages">
              <el-pagination
                  @current-change="brgHandleCurrentChange"
                  :hide-on-single-page="true"
                  :page-size="24"
                  layout="prev, pager, next, jumper"
                  :total="brg.total">
              </el-pagination>
            </div>
          </div>
          <!--    供应商单位      -->
          <div class="link_item_warp">
            <div class="link_item_title">供应商单位</div>
            <div class="link_item_list">
              <div class="list_item_branch" v-for="item in sc.data" :key="item.id">
                <el-link style="width: 100%" :href="item.linkUrl" :underline="false">
                  <el-card class="box-card" shadow="hover" style="height: 98px;">
                    <el-image style="height: 98px; border:0;" :src="preview+item.fileId" fit="fill">
                      <div slot="error" class="image-slot">
                        <el-image style="width: 100%; height: 100%; border: 0" :src="defalutUrl" fit="fill" />
                      </div>
                    </el-image>
                  </el-card>
                </el-link>
              </div>
            </div>
            <div class="link_item_pages">
              <el-pagination
                  @current-change="scHandleCurrentChange"
                  :hide-on-single-page="true"
                  :page-size="24"
                  layout="prev, pager, next, jumper"
                  :total="sc.total">
              </el-pagination>
            </div>
          </div>
          <!--    服务客户      -->
          <div class="link_item_warp">
            <div class="link_item_title">服务客户</div>
            <div class="link_item_list">
              <div class="list_item_branch" v-for="item in cs.data" :key="item.id">
                <el-link style="width: 100%" :href="item.linkUrl" :underline="false">
                  <el-card class="box-card" shadow="hover" style="height: 98px;">
                    <el-image style="height: 98px; border:0;" :src="preview+item.fileId" fit="fill">
                      <div slot="error" class="image-slot">
                        <el-image style="width: 100%; height: 100%; border: 0" :src="defalutUrl" fit="fill" />
                      </div>
                    </el-image>
                  </el-card>
                </el-link>
              </div>
            </div>
            <div class="link_item_pages">
              <el-pagination
                  @current-change="csHandleCurrentChange"
                  :hide-on-single-page="true"
                  :page-size="24"
                  layout="prev, pager, next, jumper"
                  :total="cs.total">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import OweMapBorderCompo from '../components/OweMapBorderCompo'

export default {
  name: 'contact',
  components: {
    OweMapBorderCompo
  },
  data() {
    return {
      defalutUrl: require("../../public/statics/error/zwtp.jpg"),
      url: "/statics/banner/contact/lxwm.png",
      //公司基本信息
      baseInfo: {},
      //战略合作伙
      brg: {
        //战略合作伙伴分页数据
        data: [],
        ///战略合作伙伴总数
        total: 0,
      },
      //供应商单位
      sc: {
        //供应商单位分页数据
        data: [],
        //供应商单位总数
        total: 0,
      },
      //服务客户
      cs: {
        //服务客户分页数据
        data: [],
        //服务客户总数
        total: 0,
      },
    }
  },
  watch: {
    //监听路由变化
    $route: {
      handler() {
        this.getData();
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    //战略合作伙伴分页
    brgHandleCurrentChange(val) {
      this.getBrgLinkData(val);
    },
    //供应商单位分页
    scHandleCurrentChange(val) {
      this.getScLinkData(val);
    },
    //服务客户分页
    csHandleCurrentChange(val) {
      this.getCsLinkData(val);
    },
    //获取数据
    getData() {
      this.getBaseInfo();
      this.getBrgLinkData(1);
      this.getScLinkData(1);
      this.getCsLinkData(1);
    },
    //获取官网基本信息
    getBaseInfo() {
      this.request.get('/baseinfo/getInfo?key=' + this.config.key).then(res => {
        this.baseInfo = res.data;
      });
    },
    //获取战略合作伙伴
    getBrgLinkData(index) {
      this.request.get('/link/list?key=' + this.config.key + "&pageNum=" + index + "&pageSize=24&linkType=1").then(res => {
        this.brg.data = res.data;
        this.brg.total = res.total;
      });
    },
    //获取供应商单位
    getScLinkData(index) {
      this.request.get('/link/list?key=' + this.config.key + "&pageNum=" + index + "&pageSize=24&linkType=2").then(res => {
        this.sc.data = res.data;
        this.sc.total = res.total;
      });
    },
    //获取服务客户
    getCsLinkData(index) {
      this.request.get('/link/list?key=' + this.config.key + "&pageNum=" + index + "&pageSize=24&linkType=3").then(res => {
        this.cs.data = res.data;
        this.cs.total = res.total;
      });
    },
  }
}
</script>
<style>
@import "../assets/styles/pages/contact.css";

.map_border .poi-tab {
  display: none !important;
}
</style>
